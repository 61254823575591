import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Aplicatie from '../views/Aplicatie.vue'
import Utils from '../views/Utils.vue'
import Imprimante from '../views/Imprimante.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aplicatie',
    name: 'aplicatie',
    component: Aplicatie
  },
  {
    path: '/utils',
    name: 'utils',
    component: Utils
  },  
  {
    path: '/imprimante',
    name: 'imprimante',
    component: Imprimante
  },    
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  
  base: process.env.BASE_URL,
  routes
})

export default router
