<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="splash2.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Inventar Motivation
        </h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <p class="subheading font-weight-regular">
          Folositi meniul sus-dreapta pentru operatiuni!
        </p>
        <p class="subheading font-weight-bold">
          Clientul se foloseste pentru laptopurile Motivation.
        </p>
        <p class="subheading font-weight-bold">
          Formularul de imprimante se poate completa de pe telefon. Se poate face poza direct cu camera telefonului.
        </p>                
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({

    }),
  }
</script>
