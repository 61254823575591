<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="8" xs="6">
          <v-card class="pa-3">
            <v-card-title class="pa-0">
              Formular imprimante:
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="dispozitiv.nume_utilizator"
                :counter="100"
                :rules="nameRules"
                label="Nume"
                required
                prepend-icon="mdi-account"
              ></v-text-field>

              <v-text-field
                v-model="dispozitiv.email_utilizator"
                :rules="emailRules"
                label="E-mail"
                prepend-icon="mdi-email"
                required
              ></v-text-field>

              <v-select v-if="echipe"
                v-model="dispozitiv.echipa_utilizator"
                :items="echipe"
                item-text = "nume"
                item-value = "nume"
                :rules="[(v) => !!v || 'Echipa este obligatorie!']"
                label="Echipa"
                prepend-icon="mdi-microsoft-teams"
                required
              ></v-select>

              <v-text-field
                v-model="dispozitiv.device_model"
                :counter="30"
                :rules="nameRules"
                label="Model"
                prepend-icon="mdi-printer"
                required
              ></v-text-field>
              <v-text-field
                v-model="dispozitiv.serial_number"
                :counter="20"
                :rules="nameRules"
                label="Serial Number"
                prepend-icon="mdi-counter"
                required
              ></v-text-field>
              <v-file-input                
                label="Poza"
                v-model="dispozitiv_photo"
                prepend-icon="mdi-camera"
                required
              ></v-file-input>

              <v-checkbox
                v-model="checkbox"
                :rules="[(v) => !!v || 'Trebuie sa confirmi pentru a trimite!']"
                label="Confirma"
                required
              ></v-checkbox>

              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"                
                @click="validate"
              >
                Trimite
              </v-btn>
            </v-form>
          </v-card>
          
        </v-col>
      </v-row>
            <v-snackbar
                  v-model="trimis"
                  :timeout="timeout"
                  top
                  rounded
                  color="green lighten-1"             
                >
                  
                    {{msg}}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="trimis = false"
                    >
                      Inchide
                    </v-btn>
                  </template>
            </v-snackbar>      
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios"
import echipemot from "@/data/echipe.json"

export default {
  data: () => ({
    api_url: 'https://sv4api.motivation.ro/',
    dispozitiv: {
      nume_utilizator: '',
      email_utilizator: '',
      echipa_utilizator: '',
      device_model: '',
      serial_number: '',
      photo: '',
      tip: 'imprimanta'
    },
    dispozitiv_photo: [],
    dispozitiv_blank: {
      nume_utilizator: '',
      email_utilizator: '',
      echipa_utilizator: '',
      device_model: '',
      serial_number: '',
      photo: ''
    },
    valid: true,
    timeout: 92000,    
    name: '',
    fileRules: [
      value => !value || value == null || 'Fisierul trebuie sa fie!',
    ],
    nameRules: [
      (v) => !!v || 'Campul este Obligatoriu',
      (v) => (v && v.length >= 4) || 'Campul trebuie sa fie mai mare de 5 caractere!',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail este obligatoriu',
      (v) => /.+@.+\..+/.test(v) || 'E-mail trebuie sa fie valid',
    ],
    checkbox: false,
    echipe: echipemot,
    trimis: false,
    msg: 'Success! Dispozitivul a fost trimis.',
    msgfail: 'Fisierul este necesar!'
  }),

  methods: {
    reset() {
      this.$refs.form.reset()
    },
    validate() {
      if(this.$refs.form.validate() == true) {

      const fileInput = this.dispozitiv_photo
      const formData = new FormData();
      formData.append('title', 'fisierupload.jpg');
      formData.append('folder', '98a866bf-cf6c-4ed6-843c-9d96a6a6bf4c');
      formData.append('file', fileInput);

      axios.post(this.api_url + 'files', formData)
        .then(response =>{
            if(response.status == '200'){
              this.dispozitiv.photo = response.data.data.id
              axios.post(this.api_url + 'items/dispozitiv', this.dispozitiv)
              .then(response =>{
                  if(response.status == '200'){
                    this.trimis = !this.trimis;
                    this.dispozitiv = this.dispozitiv_blank
                    this.reset()
                  }
              })
              .catch(error => {
                  console.log('ERROR', error)
              })              
            }
        })
        .catch(error => {
            console.log('ERROR', error)
        })
      }
    }
  },
  mounted(){
  },
};
</script>
