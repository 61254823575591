<template>
  <v-app>
    <v-app-bar
      app
      color="blue-grey darken-1"
      dark dense
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="splash.png"
          transition="scale-transition"
          width="40"
        />
        <div class="title" >Inventar</div>
      </div>

      <v-spacer></v-spacer>

      <v-btn text to="/aplicatie">
        Aplicatie
      </v-btn>
      <v-btn text to="/utils">
        Utils
      </v-btn>      
      <v-btn text to="/imprimante">
        Imprimante
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view class="pa-2"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
