<template>
    <div align="center">
        <v-row >
            <v-col cols="12">
            <h1>Motivation inventar IT 2020</h1>            
            <p>
                <v-btn height="100" elevation="10" href="client.exe" color="red lighten-1" rounded>
                    <div class="pa-6" >
                        <span class="h3">Descarca</span>
                    </div>
                </v-btn>                
            </p>


            <p>Dupa descarcare rulati aplicatia. Daca primiti eroare smartscreen apasati pe <strong>More Info</strong> si apoi <strong>"Run anyway"</strong></p>
            <p>Uneori Windows, din lipsa acelui certificat poate tine fisierul in carantina. Daca se intampla asta va rugam sa ne <strong>contactati.</strong></p>
            </v-col>
        </v-row>        
        <v-row justify="center">
            <v-col cols="4">
                <v-img src="@/assets/smartscreen1.png" width="500" contain></v-img>
            </v-col>
            <v-col cols="4">
                <v-img src="@/assets/smartscreen2.png" width="500" contain></v-img>
            </v-col>
        </v-row>
    </div>        
</template>

<script>
export default {

}
</script>

<style>

</style>