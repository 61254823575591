<template>
    <div align="center">
        <v-row >
            <v-col cols="12">
                <h1>Motivation Utils</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h1>Office 2013</h1>
                <p>
                    <a href="https://learn.microsoft.com/en-us/microsoft-365/admin/security-and-compliance/enable-modern-authentication?view=o365-worldwide">https://learn.microsoft.com/en-us/microsoft-365/admin/security-and-compliance/enable-modern-authentication?view=o365-worldwide</a>
                </p>
                <p>
                <v-btn target="_blank" height="100" elevation="10" href="outlook.reg" color="red lighten-1" rounded>
                    <div class="pa-6" >
                        <span class="h3">Descarca Reg</span>
                    </div>
                </v-btn>                
            </p>                

            </v-col>

        </v-row>
        <!-- <v-row justify="center">
            <v-col cols="4">
                <v-img src="@/assets/smartscreen1.png" width="500" contain></v-img>
            </v-col>
            <v-col cols="4">
                <v-img src="@/assets/smartscreen2.png" width="500" contain></v-img>
            </v-col>
        </v-row> -->
    </div>        
</template>

<script>
export default {

}
</script>

<style>

</style>